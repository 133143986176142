import React from "react";
import { ThemeProvider } from 'styled-components';
import { lightTheme } from './src/assets/theme/main';
import GlobalStyle from './src/assets/theme/global';


export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={lightTheme}>
    <GlobalStyle />
    {element}
  </ThemeProvider>
);
