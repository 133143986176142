import { css } from 'styled-components';

export const Normalize = css`
  html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{font-size:100%;font:inherit;padding:0;border:0;margin:0;vertical-align:baseline}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}.clear{clear:both}

  html {
      font-family: sans-serif;
      -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
  }
  body {
      margin: 0;
  }
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section,
  summary {
      display: block;
  }
  audio,
  canvas,
  progress,
  video {
      display: inline-block;
      vertical-align: baseline;
  }
  audio:not([controls]) {
      display: none;
      height: 0;
  }
  [hidden],
  template {
      display: none;
  }


  a {
      background-color: transparent;
  }
  a:active,
  a:hover {
      outline: 0;
  }
  abbr[title] {
      border-bottom: 1px dotted;
  }
  b,
  strong {
      font-weight: bold;
  }
  dfn {
      font-style: italic;
  }
  mark {
      color: #000;
      background: #ff0;
  }
  small {
      font-size: 80%;
  }
  sub,
  sup {
      position: relative;
      font-size: 75%;
      line-height: 0;
      vertical-align: baseline;
  }
  sup {
      top: -.5em;
  }
  sub {
      bottom: -.25em;
  }
  img {
      border: 0;
  }
  svg:not(:root) {
      overflow: hidden;
  }
  figure {
      margin: 1em 40px;
  }
  hr {
      height: 0;
      -webkit-box-sizing: content-box;
          -moz-box-sizing: content-box;
              box-sizing: content-box;
  }
  pre {
      overflow: auto;
  }
  code,
  kbd,
  pre,
  samp {
      font-family: monospace, monospace;
      font-size: 1em;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
      margin: 0;
      font: inherit;
      color: inherit;
  }
  button {
      overflow: visible;
  }
  button,
  select {
      text-transform: none;
  }
  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
      -webkit-appearance: button;
      cursor: pointer;
  }
  button[disabled],
  html input[disabled] {
      cursor: default;
  }
  button::-moz-focus-inner,
  input::-moz-focus-inner {
      padding: 0;
      border: 0;
  }
  input {
      line-height: normal;
  }
  input[type="checkbox"],
  input[type="radio"] {
      -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
              box-sizing: border-box;
      padding: 0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
      height: auto;
  }
  input[type="search"] {
      -webkit-box-sizing: content-box;
          -moz-box-sizing: content-box;
              box-sizing: content-box;
      -webkit-appearance: textfield;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
      -webkit-appearance: none;
  }
  fieldset {
      padding: .35em .625em .75em;
      margin: 0 2px;
      border: 1px solid #c0c0c0;
  }
  legend {
      padding: 0;
      border: 0;
  }
  textarea {
      overflow: auto;
  }
  optgroup {
      font-weight: bold;
  }
  table {
      border-spacing: 0;
      border-collapse: collapse;
  }
  td,
  th {
      padding: 0;
  }

  * {
      -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
              box-sizing: border-box;
  }
  *:before,
  *:after {
      -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
              box-sizing: border-box;
  }
`;

export default Normalize;
